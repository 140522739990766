import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Call App Settings`}</h1>
    <p>{`Glossary of the main concepts to understand Engagement Cloud Call settings.`}</p>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul">{`A Manager with access to Contact Center & Analytics Studio.`}</li>
    </ul>
    <h2>{`Call app > Settings`}</h2>
    <p>{`The Call App is used to manage in real time calls contact center performance and also configure some general settings related to call behaviour. Some of this settings are also available on Teams app and can be configure on team or skill level.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Team and skill level override global settings.`}</p>
    </blockquote>
    <p>{`This is an App that can be found in the console.`}</p>
    <ol>
      <li parentName="ol">{`On the bottom left corner of Contact Center and Analytics Studio desktop, click icon `}<em parentName="li">{`App`}</em>{`.`}</li>
      <li parentName="ol">{`Scroll down until you find section `}<em parentName="li">{`Contact Center`}</em>{`.
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/2f735/contact-center.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "53.666666666666664%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAAC40lEQVQozzWR0VNUZRjGzx/RTF1kMakjiQWJCCtQ7IZCDqzDqKGiYeKN410NogJjOBlusO3CgrvrruTuEbdgkT3n+76zZxd3lS00U6RspqmGpsYLZ5q66S/4NRzp4r18fs/zPo/2Qp/Bi+cMXjpv8PKg4NW+FK98nKCsN0nZmSSv9SXZeDbJ5nM6W87rlPfrvN6vs3VAp2JQp2ooRaXPpGpE8taIQNswKNgwaLJpSFB2waDsQoZNQwYbhzKUf2qw9TOTbcMmb1w2nws/F45w+6ig2v/8agLSuZ0BiVZxycITKuLy56gZtan0KSqGBbVfZKn0Sd70Ccd9+6ik2r8uDEpqg5K6MYlrXOIKSXaFJPUTEs09XmDu0e+8F77DgLHMRbVCa6SAWPmD9liBbT7DcXYg64D/xQ2TksYrkrfDknfCkqaIRNsxkuWs8RhXwOb4jSVOpu7hmcxzKf8jrdEFagKC+pByII3rgKaIwh1ReKKK5pji3ZhiT9xid1yhVfmU81p90GaHX1E+nHF6Kb88T+2YoDposHPcoGFSUDdhOCkawiZNVwWemKQxarBnStEUM2meEmi7AjZnjGXckzlOpJb4MPUtnrDNQHaZ5miWU3NL9MyU8F7P0Su+oy1h85G4T0/6LsdmilwsLNMxbfNJ4SGnzEW0lvBtSr8+wxsvMrH4M/F7v9Cp32H1r385eKOA/v1vjJV+oie9SPrhKkdni5hP/sRf+oH+hQc8fvo33fO3Ka0+Y/z+EzRXMMvp9APcV2xaojnapxacnrzX87ijkg49T3vCpi2R5YN0kXbd4sR8ka50nvdnbE6aBQ7MWBzPLNB1y0ZbW3Ll6T903/yGSn/GWc5ZLSKcspvjkpYvFXsTir1JiXda4b0p6fhKOaADs4pDcxadtywOZyy0xlCWXvMRLVdz1IWEM/9asjXY7muKVgdm0aYr9k0rOlIW+7+2ODhr0TlncWQ+S5dhccy06JYW/wGhfT/2ul5BcwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/e93cc/contact-center.webp 300w", "/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/b0544/contact-center.webp 600w", "/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/68fc1/contact-center.webp 1200w", "/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/a2303/contact-center.webp 1800w", "/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/4293a/contact-center.webp 2400w", "/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/3ebad/contact-center.webp 2438w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/eed55/contact-center.png 300w", "/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/7491f/contact-center.png 600w", "/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/8537d/contact-center.png 1200w", "/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/d2cc9/contact-center.png 1800w", "/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/00711/contact-center.png 2400w", "/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/2f735/contact-center.png 2438w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/48d6b8bc80d0d9dc5c80713ac07b4c9d/8537d/contact-center.png",
                "alt": "contact-center",
                "title": "contact-center",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Call`}</em>{`.
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d03522ebf5cc5e602feb0a6f5ccc6bfa/28e7d/call-app.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "59.333333333333336%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABjklEQVQoz62SwY6bMBCG/f4P1EtPe9xDFVWqdjdLkxBIAsEGHGODjW3+yi5Lkm5Oq470idGM558ZG/LtR4J1mmNzPGN/rpHN7Et25cyQVfUd+UwWciXFoWJ42eUgOypwa9M0RT7Z5O/wzmHy7hrDBDsakPWBxoB1HtZaVJSBXwS89xithfdT9JVSkFKikzJ+OecQQiyxkG+aBuRXeoIZegzDgL7voZSEGQ201jjmOZIkQdtySKkgxAV9r65nezU3UnDGYLXJQJKixmTH2MU7j14PGIyB1RqqaeIEsh+gzQhjBmgtMI4jWsHBLg1a1qJpeGzw9LYFec1K2CCidVw5TBaSwQ8rhzUopTidjqjrOk4T1+y6OF0nO3RSAdbgeZuB7JmYHwMPLdyfcy4S/Ef2Ubo6lCAp5XPhtLzwLZ+KH5xxc6PVoQBJ2Sx4U3wr9qjBv40+BH8ey/Af8iUYRL+CXQQLkFPb4X/Zy7kCeXrbI6Ut1gVDUjIkZ4bfCzU21ZUtvWfH/rKlDAW/4Pv7O/4AAOSgcUmHtmUAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d03522ebf5cc5e602feb0a6f5ccc6bfa/e93cc/call-app.webp 300w", "/static/d03522ebf5cc5e602feb0a6f5ccc6bfa/b0544/call-app.webp 600w", "/static/d03522ebf5cc5e602feb0a6f5ccc6bfa/68fc1/call-app.webp 1200w", "/static/d03522ebf5cc5e602feb0a6f5ccc6bfa/a2303/call-app.webp 1800w", "/static/d03522ebf5cc5e602feb0a6f5ccc6bfa/1f983/call-app.webp 2158w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d03522ebf5cc5e602feb0a6f5ccc6bfa/eed55/call-app.png 300w", "/static/d03522ebf5cc5e602feb0a6f5ccc6bfa/7491f/call-app.png 600w", "/static/d03522ebf5cc5e602feb0a6f5ccc6bfa/8537d/call-app.png 1200w", "/static/d03522ebf5cc5e602feb0a6f5ccc6bfa/d2cc9/call-app.png 1800w", "/static/d03522ebf5cc5e602feb0a6f5ccc6bfa/28e7d/call-app.png 2158w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/d03522ebf5cc5e602feb0a6f5ccc6bfa/8537d/call-app.png",
                "alt": "call-app",
                "title": "call-app",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Settings`}</em>{`.`}</li>
    </ol>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Ring time in destinations`}</strong>{` - Maximum time a call rings before moving to the next destination.`}</li>
      <li parentName="ul"><strong parentName="li">{`Contact Order`}</strong>{` - Defines if contacts are called in a `}<em parentName="li">{`sequential`}</em>{` (one by one) or `}<em parentName="li">{`random`}</em>{` order.`}</li>
      <li parentName="ul"><strong parentName="li">{`Contact attempt mode`}</strong>{` - Determines retry strategy:`}
        <ul parentName="li">
          <li parentName="ul">{`fixed intervals: calls are retried at set time gaps.`}</li>
          <li parentName="ul">{`specific intervals: custom retry timing for each attempt`}</li>
          <li parentName="ul">{`workflows: dynamic retry logic based on predefined conditions.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Num contact attempts (client)`}</strong>{` - Number of call attempts allowed (1-5) when using fixed intervals.`}</li>
      <li parentName="ul"><strong parentName="li">{`Time between attempts`}</strong>{` - The waiting period between call attempts when using fixed intervals.`}</li>
      <li parentName="ul"><strong parentName="li">{`Contact expiration time`}</strong>{` - – Maximum duration (in minutes) before a contact attempt expires in workflow mode.`}</li>
      <li parentName="ul"><strong parentName="li">{`Ring time client`}</strong>{` – The maximum time a call rings for the client before being marked as unanswered.`}</li>
      <li parentName="ul"><strong parentName="li">{`Limit parallel calls`}</strong>{` - Controls the number of simultaneous calls:`}
        <ul parentName="li">
          <li parentName="ul">{`Never - no limits on concurrent calls.`}</li>
          <li parentName="ul">{`Only shceduled calls - limits apply only to scheduled calls.`}</li>
          <li parentName="ul">{`Always - limits apply to all calls.`}</li>
          <li parentName="ul">{`Dynamic - Adjusts based on system conditions.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Allow multiple credits by contact`}</strong>{` - Determines if a single contact can be assigned multiple call credits (Yes/No).`}</li>
      <li parentName="ul"><strong parentName="li">{`Accept contacts after finishing credits`}</strong>{`  – Allows calls to continue even after credit exhaustion (Yes/No).`}</li>
      <li parentName="ul"><strong parentName="li">{`Answering mode`}</strong>{` - Defines how calls are answered (Immediate, Press 0, DTMF (x digit) or API)`}</li>
      <li parentName="ul"><strong parentName="li">{`Release call`}</strong>{` - If enabled (Yes), calls are automatically disconnected when conditions are met.`}</li>
      <li parentName="ul"><strong parentName="li">{`Check digit`}</strong>{` - Requires the user to enter a verification digit (Yes/No).`}</li>
      <li parentName="ul"><strong parentName="li">{`Allow video call`}</strong>{` - Enables/disables the option for video calls (Yes/No)`}</li>
      <li parentName="ul"><strong parentName="li">{`Classification`}</strong>{`  Defines call categorization for Contact and Schedule.`}</li>
      <li parentName="ul"><strong parentName="li">{`Script`}</strong>{` - For write a script to be shown on `}<em parentName="li">{`Script`}</em>{` tab on Bycard.`}</li>
      <li parentName="ul"><strong parentName="li">{`Contact notifications`}</strong>{` - Where notifications can be managed by defining the `}<em parentName="li">{`Type of email classification`}</em>{`, `}<em parentName="li">{`Contact notifications by email`}</em>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Type SMS notification`}</strong>{`- Defines SMS notification scope (Global, Operator, Global and Operator)`}</li>
      <li parentName="ul"><strong parentName="li">{`Contact notification by SMS`}</strong>{` - Determines if and when SMS notifications are sent for contacts.`}</li>
      <li parentName="ul"><strong parentName="li">{`Country management`}</strong>{` - .`}</li>
      <li parentName="ul"><strong parentName="li">{`Default management`}</strong>{` -  Sets system-wide default configurations for call settings.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      